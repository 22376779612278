import React, { useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import styled from "styled-components"
import { FaAlignRight } from "react-icons/fa"
import links from "../constants/links"
import socialIcons from "../constants/social-icons"
import Image from "gatsby-image"
import {
  test,
  mainTransition,
  mainBlack,
  mainSpacing,
  mainWhite,
  pallete,
  mainGrey,
  darkGrey,
} from "../theme/globalStyle"

const logoQuery = graphql`
  {
    default: file(relativePath: { eq: "logo-maker3.png" }) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Navbar = ({ className }) => {
  const logo = useStaticQuery(logoQuery)

  const [isOpen, setNav] = useState(false)

  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  return (
    <nav className={className}>
      <div className="nav-center">
        <div className="nav-header">
          {/* <Image
            fixed={logo.default.childImageSharp.fixed}
            className="img"
          ></Image> */}
          <h1>ES</h1>
          <button type="button" className="logo-btn" onClick={toggleNav}>
            <FaAlignRight className="logo-icon" />
          </button>
        </div>
        <ul className={isOpen ? "nav-links show-nav" : "nav-links"}>
          {links.map((item, index) => {
            return (
              <li key={index}>
                <a href={item.path}>{item.text}</a>
              </li>
            )
          })}
        </ul>
        <div className="nav-social-links">
          {socialIcons.map((item, index) => {
            return (
              <a key={index} href={item.url} target="_blank">
                {item.icon}
              </a>
            )
          })}
        </div>
      </div>
    </nav>
  )
}

export default styled(Navbar)`
  background: ${pallete[0]};

  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin: 0;
      font-weight: bold;
    }
    .img {
      background: blue;
    }
  }

  .logo-btn {
    background: transparent;
    border: none;
    outline: none;

    &:hover {
      cursor: pointer;
    }
  }

  .logo-icon {
    color: ${pallete[4]};
    font-size: 1.5rem;
  }

  .nav-links {
    list-style-type: none;
    transition: ${mainTransition};
    height: 0;
    overflow: hidden;

    a {
      display: block;
      padding: 1rem 1.25rem;
      text-decoration: none;
      text-transform: uppercase;
      color: ${pallete[4]};
      transition: ${mainTransition};
      font-weight: bold;
      letter-spacing: ${mainSpacing};

      &:hover {
        color: ${darkGrey};
      }
    }
  }

  .show-nav {
    height: 226px;
  }

  .nav-social-links {
    display: none;
  }

  @media screen and (min-width: 576px) {
    .navbar {
      padding: 0 2rem;
    }
  }

  @media screen and (min-width: 700px) {
    .logo-btn {
      display: none;
    }
    .nav-center {
      max-width: 1170px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .nav-links {
      height: auto;
      display: flex;
    }

    .nav-social-links {
      display: flex;
      line-height: 0;

      a {
        color: ${pallete[4]};
        margin: 0 0.5rem;
        font-size: 1.2rem;
        transition: ${mainTransition};

        &:hover {
          color: ${mainBlack};
          transform: translateY(-5px);
        }
      }
    }
  }
`
