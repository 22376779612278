import React from "react"

import GlobalStyle from "../theme/globalStyle"
import Navbar from "./Navbar"
import Footer from "./Footer"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")("a[href*='#']")
}

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
