import React from "react"

import styled from "styled-components"

import Subtitle from "../Subtitle"
import Experience from "./Experience"

const Education = ({ className }) => {
  return (
    <div className={className}>
      <div className="content-centered">
        <div className="subtitle">
          <Subtitle subtitle="Education" />
        </div>
        <div className="content">
          <Experience
            place="Fordham University"
            title="Computer Science, B.Sc."
            date="September 2015 - May 2019"
          >
            <p>
              My formal education at Fordham was mainly based in C++ but I also
              utilized some Java and Python. Here, I learned the fundamentals of
              computer science and more. I had taken a variety of courses, from
              the basics of CS to a Theory of Computation course, and courses
              like Algorithims and high level mathematics in between.
            </p>
            <p>
              I also enrolled in elective courses like Data Mining and two
              graduate courses my senior year: Machine Learning and Artificial
              Intelligence. It was in these courses where I supplemented my
              self-taught understanding of Data Science with in-depth, highly
              complex, theoretical components.
            </p>
          </Experience>
        </div>
      </div>
    </div>
  )
}

export default styled(Education)`
  color: #000;

  .content-centered {
    justify-content: center;
    margin: 0 5%;

    .subtitle {
      margin: 0 1%;
      padding-bottom: 5px;
    }
  }
`
