import React from "react"
import { Link } from "gatsby"

import styled from "styled-components"
import links from "../constants/links"
import socialIcons from "../constants/social-icons"
import {
  test,
  mainBlack,
  mainWhite,
  mainSpacing,
  mainTransition,
  pallete,
  darkGrey,
} from "../theme/globalStyle"

const Footer = ({ className }) => {
  return (
    <footer className={className}>
      <div className="links">
        {links.map((item, index) => {
          return (
            <Link key={index} to={item.path}>
              {item.text}
            </Link>
          )
        })}
      </div>
      <div className="icons">
        {socialIcons.map((item, index) => {
          return (
            <a key={index} href={item.url} target="_blank">
              {item.icon}
            </a>
          )
        })}
      </div>
      <div className="copyright">
        copyright &copy; Eric Sirinian {new Date().getFullYear()} all rights
        reserved.
      </div>
    </footer>
  )
}

export default styled(Footer)`
  margin-top: auto;
  background: ${pallete[0]};
  padding: 2rem;
  text-align: center;
  color: ${pallete[4]};

  .links {
    a {
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      color: ${pallete[4]};
      margin: 0.5rem 1rem;
      letter-spacing: ${mainSpacing};
      transition: ${mainTransition};
      font-weight: bold;

      &:hover {
        color: ${darkGrey};
      }
    }
  }

  .icons {
    a {
      display: inline-block;
      margin: 1rem;
      color: ${pallete[4]};
      transition: ${mainTransition};

      &:hover {
        color: ${darkGrey};
      }
    }
  }

  .copyright {
    text-transform: capitalize;
    letter-spacing: ${mainSpacing};
    line-height: 2;
  }
`
