export default [
  {
    title: "NN Adult Classification",
    url: "https://github.com/esirinian/nn-adult-classification",
    desc:
      "Used the “Adult” Data Set from the UCI Machine Learning Repository with the goal of comparing the classification \
      methods of Neural Networks and applying PCA to logistic regression. The goal was to successfully classify whether a person earns a salary \
      of $50,000 USD or not based on certain features about the individual.",
    tags: ["Python", "Scikit-learn", "NN", "ML"],
  },
  {
    title: "NLP: Brown Corpus",
    url: "https://github.com/esirinian/brown-corpus-nlp",
    desc:
      "Natrual Language processing: text classification using on a series of texts in the Brown Corpus. Utilized two methods of NLP analysis: Bag of Words and TF-IDF. \
      Measured accuracy across three different models: Random Forest Classifier, Logistic Regression, and Multinomial Naive Bayes. TF-IDF resulted in higher accuracy overall, \
      achieving a high of 82% with Logisitc Regression.",
    tags: ["Python", "Scikit-learn", "NLP", "ML"],
  },
  {
    title: "Data Science Fundamentals",
    url: "https://github.com/esirinian/fundamentals-capstone",
    desc:
      "Demonstration of base data science fundamentals.  Generated visuals and reached insights based on United Nations data on gender in the Middle East. Sought to explore \
      gender disparity across multiple aspects of life (education, work, etc.) and their relationship with one another.",
    tags: ["Python", "Numpy", "Pandas"],
  },
  {
    title: "House Accounts",
    url: "https://github.com",
    desc:
      "Project in progress.  Full stack application to streamline specific accounting work. Utilizes a iOS front-end written in Swift, a RESTful API created with Django, and another front-end with React.  \
      Employees are to fill out a form on an iOS device which will then communicate to the API endpoints and store the data in a PostgreSQL database.  The employer will then have access to the data on \
      the React front-end and will be able to make complex queries to better understand transactions in their entirety.",
    tags: ["Django", "Swift", "React", "PostgreSQL"],
  },
]
