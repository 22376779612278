import React from "react"

import styled from "styled-components"
import {
  pallete,
  darkGrey,
  mainWhite,
  darkShadow,
  lightShadow,
  test,
} from "../../theme/globalStyle"
import { FaRegFolderOpen, FaGithub } from "react-icons/fa"

const Card = ({ title, url, children, tags, className }) => {
  return (
    <div className={className}>
      <div className="container">
        <div className="header">
          <FaRegFolderOpen className="folder-icon" />
          <h3>{title}</h3>
          <a href={url} target="_blank">
            <FaGithub className="git-icon" />
          </a>
        </div>
        <div className="desc">
          <p>{children}</p>
        </div>
        <div className="tags">
          <ul>
            {tags.map((item, index) => {
              return <li key={index}>{item}</li>
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default styled(Card)`
  width: 300px;
  height: 400px;
  background: ${pallete[4]};
  position: relative;
  box-shadow: ${lightShadow};

  padding-bottom: 5rem;

  &:hover {
    box-shadow: ${darkShadow};
    transform: translateY(-5px);
  }

  .container {
    margin: 10px;
    padding-top: 1px;

    .header {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
      align-items: center;
      width: inherit;
      justify-items: center;
      margin: 1.25rem 1rem 0.25rem 1rem;
      .folder-icon {
        font-size: 1.7rem;
      }
      h3 {
        color: ${mainWhite};
        margin: 0;
        text-align: center;
        letter-spacing: 2px;
      }
      a {
        text-decoration: none;
        color: ${mainWhite};
        .git-icon {
          font-size: 1.4rem;
        }

        &:hover {
          color: slateblue;
        }
      }
    }
    .desc {
      justify-content: center;
      margin: 0 1.25rem;
      p {
        font-size: 13px;
      }
    }
    .tags {
      /* margin-left: 1.25rem; */
      left: 1rem;
      position: absolute;
      bottom: 20px;
      ul {
        list-style: none;
        li {
          display: inline;
          padding-right: 1.2rem;
          color: ${darkGrey};
          font-size: 13.5px;
        }
      }
    }
  }
`
