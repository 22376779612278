import React from "react"

import styled from "styled-components"
import Title from "../Title"
import ProjectsGroup from "./ProjectsGroup"
import { pallete } from "../../theme/globalStyle"

const Projects = ({ className }) => {
  return (
    <div id="projects" className={className}>
      <div className="content-centered">
        <Title title="Projects" />
        <div className="projects">
          <ProjectsGroup />
        </div>
      </div>
    </div>
  )
}

export default styled(Projects)`
  /* background: ${pallete[1]}; */
  /* background: ${pallete[2]}; */
  background: white;

  .content-centered {
    margin-left: 5%;
    margin-right: 5%;

    .projects {
      height: 100%;
    }
    
  }
`
